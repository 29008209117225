<template>
  <v-app fluid>
    <Navbar
      @update-language="updateLanguage"
      @show-menu-drawer="showMenuDrawer"
      :nav-bar-items="navBarItems"
      :nav-bar-extension-items="navBarExtensionItems"
      :language-flags="languageFlags"
    ></Navbar>
    <MenuDrawer
      ref="menuDrawer"
      @update-language="updateLanguage"
      :nav-bar-items="navBarItems"
      :nav-bar-extension-items="navBarExtensionItems"
      :language-flags="languageFlags"
    ></MenuDrawer>

    <v-content>
      <router-view></router-view>
    </v-content>

    <FloatingContactBox v-if="$route.path !== '/contact'" :form-details="formDetails"></FloatingContactBox>
    <Footer
      :nav-bar-items="navBarItems"
      :nav-bar-extension-items="navBarExtensionItems"
      :contact-details="contactDetails"
    ></Footer>
    <CookieInfo></CookieInfo>
  </v-app>
</template>

<script>
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import FloatingContactBox from './components/FloatingContactBox'
import CookieInfo from './components/CookieInfo'
import MenuDrawer from './components/MenuDrawer'

export default {
  name: 'App',

  components: {
    Navbar,
    Footer,
    FloatingContactBox,
    CookieInfo,
    MenuDrawer
  },

  data: () => ({
    isMenuDrawer: false,
    language: 'pl',
    navBarItems: [
      {
        key: 'home',
        title: 'Strona główna',
        link: '/',
        icon: 'mdi-home'
      },
      {
        key: 'about',
        title: 'O nas',
        link: '/about',
        icon: 'mdi-information'
      },
      {
        key: 'contact',
        title: 'Kontakt',
        link: '/contact',
        icon: 'mdi-message-text'
      }
    ],
    navBarExtensionItems: [
      {
        key: 'pyrolysis',
        title: 'Piroliza opon',
        link: '/pyrolysis'
      },
      {
        key: 'carbon',
        title: 'Przekształcanie sadzy',
        link: '/carbon'
      }
    ],
    languageFlags: [
      {
        tooltip: 'Polski',
        code: 'pl',
        callback: () => console.log('Polski')
      }
      // {
      //   tooltip: 'Angielski',
      //   code: 'gb',
      //   callback: () => console.log('Angielski')
      // }
    ],
    contactDetails: {
      email: {
        text: ['o', 'f', 'f', 'i', 'c', 'e', '@', 'e', 'l', 'i', 'b', 'o', 'r', 't', 'e', 'c', 'h', 'n', 'o', 'l', 'o', 'g', 'y', '.', 'c', 'o', 'm'],
        icon: 'alternate_email'
      },
      phone: {
        text: ['+', '4', '8', ' ', ' 5', '0', '9', ' ', '0', '3', '3', ' ', '7', '3', '1'],
        icon: 'call'
      },
      address: {
        text: [['C', 'z', 'e', 'p', 'c', 'a', ' ', '8', ','], ['3', '0', '-', '0', '9', '4', ' ', 'K', 'r', 'a', 'k', 'ó', 'w']],
        icon: 'place'
      }
    },
    formDetails: {
      valid: false,
      name: {
        data: '',
        label: 'Imię i Nazwisko',
        rules: [
          v => !!v || 'To pole jest wymagane'
        ]
      },
      email: {
        data: '',
        label: 'E-mail',
        rules: [
          v => !!v || 'To pole jest wymagane',
          v => /^.+@.+\..+$/.test(v) || 'Podany e-mail jest nieprawidłowy'
        ]
      },
      phone: {
        data: '',
        label: 'Telefon kontaktowy',
        rules: [
          v => !!v || 'To pole jest wymagane',
          v => /^(\+?\d{0,3}[ \-]?\d{2,3}[ \-]?\d{2,3}[ \-]?\d{2,3}[ \-]?(\d{2,3})?)$/.test(v) || 'Podany numer telefonu jest nieprawidłowy'
        ]
      },
      message: {
        data: '',
        label: 'Treść wiadomości',
        rules: [
          v => !!v || 'To pole jest wymagane'
        ]
      },
      rodo: 'Dane osobowe wpisane do formularza kontaktowego będą przetwarzane przez ' +
          'Elibor Technology Sp. z o.o. ' +
          'w celu udzielenia odpowiedzi lub przedstawienia oferty. Jeżeli udzielenie odpowiedzi będzie wiązało się z przesłaniem informacji marketingowych, ' +
          'poprzez uzupełnienie pól formularza kontaktowego wyraźnie potwierdzasz zgodę na przekazanie Ci informacji o wybranych produktach lub usługach na podany przez Ciebie adres e-mail lub numer telefonu. ' +
          'Masz prawo do cofnięcia zgody w każdym momencie bez podawania przyczyny. ' +
          'Przysługują Ci prawa dostępu do Twoich danych osobowych oraz ich zmiany.'
    }
  }),
  mounted: function () {
    this.$router.addRoutes([
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('./views/Contact.vue'),
        props: {
          contactDetails: this.contactDetails,
          formDetails: this.formDetails
        }
      }
    ])
  },
  computed: {},
  methods: {
    updateLanguage: function (value) {
      this.language = value
    },
    showMenuDrawer: function () {
      this.$refs.menuDrawer.showMenuDrawer()
    }
  },
  watch: {},
  filters: {}
}
</script>
<style>

  .defaultTextStyle {
    text-align: justify;
    font-size: 18px;
  }

  .defaultImageStyle {
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 21px -6px rgba(255, 255, 255, 0.2);
    -moz-box-shadow: 0px 0px 21px -6px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 21px -6px rgba(255, 255, 255, 0.2);
  }
</style>
