<template>
  <v-dialog v-model="dialog" persistent max-width="450">
    <template v-slot:activator="{ on }">
      <v-toolbar
        dense
        floating
        class="floatingContactBoxDiv"
        color="tertiary"
        v-on="on"
      >
        <v-icon color="secondary" large>mdi-card-account-phone</v-icon>
        <span style="padding-left: 12px" class="font-weight-bold">Skontaktuj się z nami!</span>
      </v-toolbar>
    </template>
    <v-card>
      <v-card-title class="headline">Napisz do nas:</v-card-title>
      <v-card-text>
        <ContactForm ref="contactForm" :form-details="formDetails" @submit-success="submitSuccess"></ContactForm>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="dialog = false">Anuluj</v-btn>
        <v-btn color="green darken-1" text @click="submitForm">Wyślij</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ContactForm from '../components/ContactForm'

export default {
  inheritAttrs: false,
  name: 'floatingContactBox',
  props: ['formDetails'],
  data: function () {
    return {
      dialog: false
    }
  },
  mounted: function () {
  },
  computed: {},
  components: {
    ContactForm
  },
  methods: {
    submitForm: function () {
      this.$refs.contactForm.sendMail()
    },
    submitSuccess () {
      var self = this
      setTimeout(function () {
        self.dialog = false
      }, 10 * 1000)
    }
  }
}
</script>
<style>
  .floatingContactBoxDiv {
    border-radius: 4px;
    border: thin solid hsla(0, 0%, 100%, .12);
    cursor: pointer;
    position: -webkit-sticky;
    position: sticky;
    width: fit-content;
    align-self: flex-end;
    margin-top: -48px;
    max-height: 48px;
    right: 32px;
    bottom: 40px;
    z-index: 2;
  }
</style>
