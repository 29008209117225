<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" lg="4" md="12" style="padding: 16px 32px">
                <v-card
                        outlined
                >
                    <v-card-title class="headline">Menu:</v-card-title>

                    <v-card-subtitle style="color: #FFFFFF">
                        <ul>
                            <router-link tag="li"
                                         v-for="item in navBarItems"
                                         :key="item.key"
                                         :to="item.link"
                                         style="cursor: pointer"
                            >{{item.title}}
                            </router-link>
                        </ul>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="12" style="padding: 16px 32px">
                <v-card
                        outlined
                >
                    <v-card-title class="headline">Produkty:</v-card-title>

                    <v-card-subtitle style="color: #FFFFFF">
                        <ul>
                            <router-link tag="li"
                                         v-for="item in navBarExtensionItems"
                                         :key="item.key"
                                         :to="item.link"
                                         style="cursor: pointer"
                            >{{item.title}}
                            </router-link>
                        </ul>
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4" md="12" style="padding: 16px 32px">
                <v-card
                        outlined
                >
                    <v-card-title class="headline">Kontakt:</v-card-title>

                    <v-card-subtitle style="color: #FFFFFF;padding-left: 12px">
                        <v-container fluid>
                            <v-row>
                                <v-col md="auto">
                                    <v-icon left color="secondary">{{ contactDetails.email.icon }}</v-icon>
                                    <span>Email:</span>
                                </v-col>
                                <v-col md="auto"><span>{{ contactDetails.email.text.join("") }}</span></v-col>
                            </v-row>
                            <v-row>
                                <v-col md="auto">
                                    <v-icon left color="secondary">{{ contactDetails.phone.icon }}</v-icon>
                                    <span>Telefon:</span>
                                </v-col>
                                <v-col md="auto"><span>{{ contactDetails.phone.text.join("") }}</span></v-col>
                            </v-row>
                            <v-row>
                                <v-col md="auto">
                                    <v-icon left color="secondary">{{ contactDetails.address.icon }}</v-icon>
                                    <span>Adres:</span>
                                </v-col>
                                <v-col md="auto"><span v-for="adressLine in contactDetails.address.text">{{ adressLine.join("") }}<br></span></v-col>
                            </v-row>
                        </v-container>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
  inheritAttrs: false,
  props: ['nav-bar-items', 'nav-bar-extension-items', 'contact-details'],
  data: function () {
    return {}
  },
  mounted: function () {
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>

    .active-router-button {
        color: #379609 !important;
    }
</style>
