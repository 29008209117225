import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
// import FlagIcon from 'vue-flag-icon'
import CountryFlag from 'vue-country-flag'

Vue.config.productionTip = false
// Vue.use(FlagIcon);
Vue.component('country-flag', CountryFlag)
new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
