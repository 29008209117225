<template>
  <div>
    <v-alert type="success" v-if="submitInfo==='success'">
      Pomyślnie wysłano!
    </v-alert>
    <v-alert type="warning" v-if="submitInfo==='warning'">
      Nie udało się wysłać formularza. Uzupełnij wszystkie dane i spróbuj jeszcze raz.
    </v-alert>
    <v-form
      id="contactForm"
      ref="form"
      v-model="formDetails.valid"
      lazy
    >
      <v-text-field
        name="name"
        v-model="formDetails.name.data"
        :rules="formDetails.name.rules"
        :label="formDetails.name.label"
        color="secondary"
        required
      ></v-text-field>
      <v-text-field
        v-model="formDetails.email.data"
        :rules="formDetails.email.rules"
        :label="formDetails.email.label"
        color="secondary"
        required
      ></v-text-field>
      <v-text-field
        v-model="formDetails.phone.data"
        :rules="formDetails.phone.rules"
        :label="formDetails.phone.label"
        color="secondary"
        required
      ></v-text-field>
      <v-textarea
        v-model="formDetails.message.data"
        :rules="formDetails.message.rules"
        :label="formDetails.message.label"
        color="secondary"
        required
      ></v-textarea>
      <br>
      <p class="rodoInfoStyle">{{ formDetails.rodo }}</p>
      <div style="text-align: right">
        <VueRecaptcha
          ref="recaptcha"
          @verify="onCaptchaVerified"
          @expired="onCaptchaExpired"
          theme="dark"
          sitekey="6LfgYfEUAAAAAB6NF-X4q9yGKo79o61V5USPVRgz"
          style="display: inline-block;"
        ></VueRecaptcha>
      </div>
    </v-form>
  </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'

export default {
  inheritAttrs: false,
  name: 'contactForm',
  props: ['formDetails'],
  data: function () {
    return {
      recaptcha: '',
      submitInfo: ''
    }
  },
  mounted: function () {
  },
  computed: {},
  components: {
    VueRecaptcha
  },
  methods: {
    sendMail: function () {
      this.$refs.form.validate()
      if (!this.formDetails.valid) {
        return
      }
      if (this.recaptcha == '') {
        return
      }
      const formData = new FormData()
      formData.append('name', this.formDetails.name.data)
      formData.append('email', this.formDetails.email.data)
      formData.append('phone', this.formDetails.phone.data)
      formData.append('message', this.formDetails.message.data)
      formData.append('recaptcha', this.recaptcha)
      axios({
        method: 'post',
        url: 'mailer/Mailer.php',
        data: formData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      }).then((response) => {
        this.submitInfo = 'success'
        this.$refs.form.reset()
        this.$emit('submit-success')
      }).catch((err) => {
        this.submitInfo = 'warning'
        this.$emit('submit-warning')
      }).then(() => {
        var self = this
        setTimeout(function () {
          self.submitInfo = ''
        }, 10 * 1000)
        this.$refs.recaptcha.reset()
      })
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.recaptcha = recaptchaToken
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset()
    }
  }
}
</script>
<style scoped>
  .rodoInfoStyle {
    font-size: 10px;
    line-height: initial;
    color: white;
    text-align: justify;
  }
</style>
