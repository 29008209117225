import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#1F1F1F',
        secondary: '#4CE809',
        tertiary: '#2A501A',
        accent: '#0000FF'
      }
    }
  },
  options: {
    customProperties: true
  },
  icons: {
    iconfont: 'md'
  }
})
