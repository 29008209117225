<template>
	<div>
		<VueperSlides
			fixed-height="500px"
			autoplay
			duration="5000"
		>
			<VueperSlide v-for="(slide, i) in slides" :key="i" :image="slide.image">
				<template v-slot:content>
					<div class="vueperslide__content-wrapper">
						<div class="vueperslide__content">{{  slide.content  }}<br><span style="font-style: italic;font-weight: normal;">{{  slide.content2  }}</span></div>
					</div>
				</template>
			</VueperSlide>
		</VueperSlides>

		<v-container fluid style="padding: 16px 32px">
			<v-card
				class="mx-auto"
				outlined
			>

				<v-card-subtitle style="color: #FFFFFF;padding-left: 22px">
					<v-container fluid>
						<v-row>
							<v-col>
								<h1 class="defaultTextStyle">
									Znaczący postęp cywilizacyjny jaki nastąpił na przestrzeni ostatnich dziesięcioleci nie byłby możliwy gdyby nie rozwój transportu.
									Z roku na rok produkcja wszelkiego rodzaju opon wzrastała, aż do poziomu ok. 2 mld opon rocznie.
								</h1><br>
								<p class="defaultTextStyle">
									Nietrudno sobie uzmysłowić, że proporcjonalnie do wzrostu produkcji, rosła również ilość odpadów.
								</p>
								<p class="defaultTextStyle">
									Zużyte opony stanowią bardzo poważne zagrożenie dla równowagi naszego środowiska, nie podlegają procesom biodegradacji,
									są trudne w składowaniu oraz zawierają bardzo dużo toksycznych związków.
								</p>
								<p class="defaultTextStyle">
									Ich niewątpliwa zaleta jaką jest wytrzymałość mechaniczna podczas użytkowania, zapewniająca osiągi i bezpieczeństwo,
									staje się olbrzymią wadą gdy ich termin przydatności bezpowrotnie minie.
								</p>
							</v-col>
						</v-row>
					</v-container>
				</v-card-subtitle>
			</v-card>
		</v-container>
	</div>
</template>
<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  inheritAttrs: false,
  name: 'Home',
  props: [],
  components: {
    VueperSlides,
    VueperSlide
  },
  data: function () {
    return {
      slides: [
        {
          title: '#1',
          content: '“Na polski rynek trafia nawet do 200 tys. ton opon rocznie. Na ich producentów nałożony jest obowiązek odzysku z rynku trzech czwartych z nich, z czego minimum 15 proc. podlega recyklingowi.”',
          content2: 'biznes.newseria.pl',
          image: require('@/assets/slides/Pyrolysis_Tires.jpg')
        },
        {
          title: '#2',
          content: '',
          content2: '',
          image: require('@/assets/slides/Pyrolysis_Highway_Eco_2.jpg')
        },
        {
          title: '#3',
          content: '',
          content2: '',
          image: require('@/assets/slides/Pyrolysis_Eco.jpg')
        }
      ]
    }
  },
  mounted: function () {
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>
	.vueperslide__content {
		font-weight: bolder;
		width: 60%;
		margin-top: auto;
		right: 0px;
		padding-left: 12px;
		margin-bottom: 2%;
		text-align: right;
		bottom: 0px;
		font-size: 150%;
		opacity: 1;
		color: #4CE809;
		text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
	}
</style>
