<template>
  <v-navigation-drawer
    v-model="isMenuDrawer"
    fixed
    app
    temporary
    style="z-index: 9999"
  >
    <v-list nav dense>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Menu:
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="item in navBarItems"
        :key="item.key"
        :to="item.link"
        active-class="active-router-button"
      >
        <v-list-item-icon>
          <v-icon color="secondary">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Produkty:
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="item in navBarExtensionItems"
        :key="item.key"
        :to="item.link"
        active-class="active-router-button"
      >
        <v-list-item-icon>
          <v-icon color="secondary">keyboard_arrow_right</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
      <template v-if="languageFlags.length > 1">
        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Change language:
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="item in languageFlags"
          :key="item.code"
          click="changeLanguage(item.code)"
        >
          <v-list-item-icon>

            <country-flag
              :country="item.code"
              size="small"
            />
          </v-list-item-icon>
          <v-list-item-title>{{ item.tooltip }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'menuDrawer',
  props: ['nav-bar-items', 'nav-bar-extension-items', 'language-flags'],
  data: function () {
    return {
      isMenuDrawer: false
    }
  },
  mounted: function () {
  },
  computed: {},
  methods: {
    changeLanguage: function (value) {
      this.$emit('update-language', value)
    },
    showMenuDrawer: function () {
      this.isMenuDrawer = true
    }
  }
}
</script>
<style scoped>
  .v-btn-separator {
    pointer-events: none;
  }

  .active-router-button {
    color: #4CE809 /*379609*/ !important;
  }

  .v-list-item__title {
    line-height: 1.2 !important;
  }

</style>
