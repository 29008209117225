<template>
    <div class="cookieInfo" v-if="cookieShow">
        Niniejsza strona wykorzystuje pliki cookies w celach statystycznych. Pozostając na stronie wyrażasz zgodę na zapisywanie ich w Twojej przeglądarce.
        Jeżeli nie wyrażasz zgody, opuść stronę. <v-btn small color="secondary" class="cookieInfoButton" @click="setCookieChecker">OK</v-btn>
    </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'cookieInfo',
  props: [],
  data: function () {
    return {
      cookieName: 'cookieChecker',
      cookieShow: false
    }
  },
  mounted: function () {
    this.cookieShow = this.isCookieChecker()
  },
  computed: {
  },
  methods: {
    setCookieChecker: function () {
      var oExpire = new Date()
      oExpire.setTime((new Date()).getTime() + 3600000 * 24 * 365)
      document.cookie = this.cookieName + '=1;expires=' + oExpire
      this.cookieShow = false
    },
    isCookieChecker: function () {
      var sStr = '; ' + document.cookie + ';'
      var nIndex = sStr.indexOf('; ' + escape(this.cookieName) + '=')
      return nIndex === -1
    }
  }
}
</script>
<style scoped>
    .cookieInfo {
        background-color: rgba(200,200,200,0.9);
        min-height: 26px;
        font-size: 16px;
        color: #000000;
        line-height: 26px;
        padding: 8px 0 8px 30px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
    }
    .cookieInfoButton {
        display: inline-block;
        border-radius: 5px;
        padding: 0 20px;
        cursor: pointer;
        float: right;
        margin: 0 60px 0 10px;
    }
</style>
