import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/pyrolysis',
    name: 'Pyrolysis',
    component: () => import('../views/Pyrolysis.vue')
  },
  {
    path: '/carbon',
    name: 'Carbon',
    component: () => import('../views/Carbon.vue')
  }
]

const router = new VueRouter({
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
