<template>
    <v-app-bar
            app
            extension-height="40"
            height="70"
            elevate-on-scroll
    >
        <router-link to="/">
            <img src="../assets/logo.svg" style="height: 50px"/>
        </router-link>
        <v-spacer></v-spacer>
        <v-btn text icon class="hidden-md-and-up" @click="showMenuDrawer()">
            <v-icon>menu</v-icon>
        </v-btn>
        <v-toolbar-items class="hidden-sm-and-down">
            <v-btn
                    v-for="item in navBarItems"
                    :key="item.key"
                    :to="item.link"
                    active-class="active-router-button"
                    text
            >
                <v-icon left color="secondary">{{ item.icon }}</v-icon>
                {{ item.title }}
            </v-btn>
            <v-btn text class="v-btn-separator" v-if="languageFlags.length > 1">
                <v-tooltip bottom
                           v-for="item in languageFlags"
                           :key="item.code"
                >
                    <template v-slot:activator="{ on }">
                        <span v-on="on"
                              @click="changeLanguage(item.code)"
                              style="pointer-events: auto; padding: 0px"
                        >
                            <country-flag
                                    :country="item.code"
                                    size="normal"
                            />
                        </span>
                    </template>
                    <span>{{item.tooltip}}</span>
                </v-tooltip>
            </v-btn>
        </v-toolbar-items>
        <template v-slot:extension v-if="$vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl">
            <v-toolbar-items>
                <v-btn text class="v-btn-separator">Produkty:</v-btn>
                <v-btn
                        v-for="item in navBarExtensionItems"
                        :key="item.key"
                        :to="item.link"
                        active-class="active-router-button"
                        text
                >{{ item.title }}
                </v-btn>
            </v-toolbar-items>
        </template>
    </v-app-bar>
</template>
<script>
export default {
  inheritAttrs: false,
  props: ['nav-bar-items', 'nav-bar-extension-items', 'language-flags'],
  data: function () {
    return {
    }
  },
  mounted: function () {
  },
  computed: {},
  methods: {
    changeLanguage: function (value) {
      this.$emit('update-language', value)
    },
    showMenuDrawer: function () {
      this.$emit('show-menu-drawer')
    }
  }
}
</script>
<style scoped>
    .v-btn-separator {
        pointer-events: none;
    }

    .active-router-button {
        color: #4CE809 /*379609*/ !important;
    }
</style>
